<template>
    <div>
        <Toast />

        <router-view :key="$route.path">
            <div class="card flex flex-column" style="height: 100%">
                <header class="flex justify-content-between gap-8 align-items-center px-6 pt-6">
                    <div class="mw-25">
                        <div class="breadcrumb-container flex mb-4 gap-4">
                            <span class="pi pi-home"></span>
                            <span class="pi pi-angle-right"></span>
                            <p class="text-xl">SESMT</p>
                            <span class="pi pi-angle-right"></span>
                            <p class="text-xl cursor-pointer">Plano de Ação</p>
                        </div>
                        <div>
                            <h4>{{ this.planoAcao?.customer?.name }} | {{ this.planoAcao?.unidade?.name }}</h4>
                        </div>
                    </div>
                    <Steps :readonly="false" :model="items" aria-label="Form Steps" class="flex-1" />
                </header>

                <div class="flex gap-8 mt-4 px-6">
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar mt-2" style="font-size: 20px"></i>
                        <p class="mt-3">
                            Data inicial:
                            {{
                                `${this.planoAcao.dataInicial?.split('-')[2]}/${this.planoAcao.dataInicial?.split('-')[1]}/${
                                    this.planoAcao.dataInicial?.split('-')[0]
                                }`
                            }}
                        </p>
                    </div>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar mt-2" style="font-size: 20px"></i>
                        <p class="mt-3">
                            Data prevista de conclusão:
                            {{
                                `${this.planoAcao.dataPrevisao?.split('-')[2]}/${this.planoAcao.dataPrevisao?.split('-')[1]}/${
                                    this.planoAcao.dataPrevisao?.split('-')[0]
                                }`
                            }}
                        </p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Objetivo: {{ this.planoAcao.objetivoAcao }}</p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Prioridade: {{ this.planoAcao.prioridadeCausa }}</p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Nome da ação: {{ this.planoAcao.nomeAcao }}</p>
                    </div>
                    <div class="flex align-items-center gap-2" style="margin-left: auto">
                        <p class="mt-3">
                            Status: <span :class="`${this.planoAcao.status}`">{{ this.planoAcao.status?.replace('_', ' ') }}</span>
                        </p>
                    </div>
                </div>
                <div class="px-6 my-4">
                    <Divider />
                </div>
                <div class="flex flex-column align-items-center mt-8">
                    <div v-if="this.anexos.length === 0" class="flex flex-column align-items-center">
                        <ListaVaziaIcone class="mb-3" />
                        <p class="mb-5" style="font-weight: 300">Ainda não há anexos</p>
                    </div>
                    <DataTable
                        v-else
                        dataKey="id"
                        :value="anexos"
                        :row-hover="true"
                        responsiveLayout="scroll"
                        breakpoint="640px"
                        :rows="10"
                        style="cursor: pointer; width: 95%"
                        selectionMode="single"
                    >
                        <template #empty> Nenhum registro encontrado. </template>

                        <template #loading> Carregando registros. Aguarde ... </template>
                        <Column field="nomeArquivo" header="Nome">
                            <template #body="slotProps">
                                <label :class="`${slotProps.data?.nomeArquivo}`">
                                    <a :href="slotProps.data?.url" target="_blank">{{ slotProps.data?.nomeArquivo }}</a>
                                </label>
                            </template>
                        </Column>
                        <Column field="prioridadeAcao" header="Data de atualização">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data ? new Date(slotProps.data.updatedAt).toLocaleString().replace(',', ' as ') : '' }}
                                </label>
                            </template>
                        </Column>
                        <Column field="tipoDeAnexo" header="Tipo de anexo">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data.planoAcaoAcompanhamento ? slotProps.data.planoAcaoAcompanhamento.tipoAtualizacao : 'GERAL' }}
                                </label>
                            </template>
                        </Column>
                        <Column bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <div class="flex flex-row gap-2">
                                    <Button
                                        @click="(e) => downloadAnexo(e, slotProps.data)"
                                        class="p-button-text p-button-secondary"
                                        :disabled="this.planoAcao.status === 'CONCLUIDO'"
                                        ><i class="pi pi-check mr-2" />Baixar</Button
                                    >
                                    <Button
                                        class="p-button-text p-button-secondary"
                                        @click="deleteFile(slotProps.data.id)"
                                        :disabled="this.planoAcao.status === 'CONCLUIDO'"
                                        ><i class="pi pi-trash mr-2" />Excluir</Button
                                    >
                                </div>
                            </template>
                        </Column>
                        <template #footer>
                            <div class="flex justify-content-between">
                                Total de anexos <span class="pr-3">{{ this.anexos.length }}</span>
                            </div>
                        </template>
                    </DataTable>
                    <input hidden type="file" ref="inputFile" @change="handleInputFile" multiple />
                    <Button
                        class="mt-4"
                        label="Subir arquivos"
                        icon="pi pi-upload"
                        style="height: 32px"
                        @click="this.openInputFile"
                        :disabled="this.planoAcao.status === 'CONCLUIDO'"
                    />
                </div>
                <div class="w-100 flex align-items-center justify-content-end mt-auto">
                    <Button label="Voltar" @click="$router.push('/plano-acao/listar')" icon="pi pi-home" iconPos="left" type="button" class="mr-7" />
                </div>
            </div>
        </router-view>
    </div>
</template>

<script>
import ListaVaziaIcone from './components/lista-vazia.vue';
import PlanoAcaoService from '../../services/planoAcaoService';
import PlanoAcaoAcompanhamentoService from '../../services/planoAcaoAcompanhamentoService';
import PlanoAcaoAnexosService from '../../services/planoAcaoAnexosService';
import axios from 'axios';

export default {
    data() {
        return {
            planoAcao: {},
            anexos: [],
            items: [
                {
                    label: 'Plano Ação',
                    to: `/plano-acao/${this.$route.params.id || 'criar'}`
                },
                {
                    label: 'Acompanhamento',
                    to: `/plano-acao/${this.$route.params.id}/acompanhamento`
                },
                {
                    label: 'Anexos',
                    to: `/plano-acao/${this.$route.params.id}/anexos`
                },
                {
                    label: 'Conclusão',
                    to: `/plano-acao/${this.$route.params.id}/conclusao`
                }
            ]
        };
    },

    components: { ListaVaziaIcone },

    mounted() {
        this.getData();
    },

    methods: {
        async getData() {
            this.$service = new PlanoAcaoAnexosService();

            const responsePlanoAcao = await new PlanoAcaoService().get('', '', this.$route.params.id);
            const responseAnexos = await this.$service.getByPlan(this.$route.params.id);

            this.planoAcao = responsePlanoAcao.data;
            this.anexos = responseAnexos.data;

            this.anexos.forEach(async (element, index) => {
                if (element.planoAcaoAcompanhamentoId) {
                    const response = await new PlanoAcaoAcompanhamentoService().get(element.planoAcaoAcompanhamentoId);
                    this.anexos[index].planoAcaoAcompanhamento = response.data;
                }
            });
        },
        openInputFile() {
            const inputFile = this.$refs.inputFile;
            inputFile.click();
        },
        async handleInputFile() {
            const inputFile = this.$refs.inputFile;

            for (let i = 0; i < inputFile.files.length; i++) {
                const formData = new FormData();

                formData.append('file', inputFile.files[i]);
                formData.append('planoAcaoId', this.$route.params.id);
                formData.append('tipo', inputFile.files[i].type);

                await this.$service.post(formData);
            }

            await this.getData();

            return this.$toast.add({
                severity: 'success',
                summary: 'Anexo(s) cadastrado(s) com sucesso',
                life: 3000
            });
        },
        async deleteFile(id) {
            await this.$service.delete(id).then(async () => {
                await this.getData();

                return this.$toast.add({
                    severity: 'success',
                    summary: 'Anexo excluido com sucesso',
                    life: 3000
                });
            });
        },
        downloadAnexo($event, record) {
            axios({
                url: record.url,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => {
                const file = window.URL.createObjectURL(new Blob([res.data]));

                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', record.nomeArquivo);
                document.body.appendChild(docUrl);
                docUrl.click();
            });
        }
    }
};
</script>

<style scoped>
.message {
    width: 452px;
}
.pi-home,
.pi-angle-right {
    font-size: 2rem;
}

.PENDENTE {
    background-color: #f7ffc6;
    color: #504d05;
}

.EM_ANDAMENTO {
    background-color: #c3f3f6;
    color: #0d0540;
}

.CONCLUIDO {
    background-color: #c3f6d9;
    color: #0e4005;
}

.EXCLUIDO {
    background-color: #ffd3d3;
    color: #700d06;
}
</style>
